@import '@silevis/reactgrid/styles.css';

.reactgrid-content {
    color: #263238;
}

.rg-celleditor-input, .rg-celleditor input {
    border: 0
}

.rg-dropdown-menu {
    z-index: 1000 !important;
}

.reactgrid-content .rg-context-menu {
    font-size: 14px;
    color: #939393;
}

.reactgrid-content .rg-context-menu .rg-context-menu-option:hover {
    background-color: #E0F7FF;
}

.rg-pane .rg-pane-top {
    z-index: 0;
}

.reactgrid-content .rg-pane-shadow.shadow-top {
    box-shadow: none;
}

.reactgrid-content .rg-pane.rg-pane-top {
    background-color: transparent;
}

.rg-cell .rg-select-cell > div {
    margin-top: 0;
}

.rg-datetime-celleditor input {
    font-size: 12px;
}


.reactgrid-content .rg-pane .rg-cell.rg-checkbox-cell label input:checked ~ span {
    background-color: #61C6E9;
}

.reactgrid-content .rg-pane .rg-cell.rg-checkbox-cell.rg-select-cell span {
    width: calc(100% - 25px);
    height: 100%;
    background-color: transparent;
    padding: 1px;
    position: relative;
}
