@import 'normalize.css';
@import 'print.css';
@import 'tables.css';
@import 'prism.css';
@import "react-grid.css";

html, body {
    font-family: Roboto, Muli, Helvetica Neue, Arial, sans-serif;
    background-color: white;
    width: 100%;
    height: 100%;
    position: relative;
    margin: 0;
    padding: 0;
}

#root {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: auto;
}

.ps > .ps__rail-y,
.ps > .ps__rail-x {
    z-index: 99;
}

a[role=button] {
    text-decoration: none;
}

[role="tooltip"] {
    z-index: 999;
}

/* Medium Devices, Desktops Only */
@media only screen and (min-width: 992px) {
    ::-webkit-scrollbar {
        width: 12px;
        height: 12px;
        background-color: rgba(0, 0, 0, 0);
    }

    ::-webkit-scrollbar:hover {
        width: 12px;
        height: 12px;
        background-color: rgba(0, 0, 0, 0.06);
    }

    ::-webkit-scrollbar-thumb {
        border: 2px solid transparent;
        box-shadow: inset 0 0 0 20px rgba(0, 0, 0, 0.24);
        border-radius: 20px;
    }

    ::-webkit-scrollbar-thumb:active {
        box-shadow: inset 0 0 0 20px rgba(0, 0, 0, 0.37);
        border-radius: 20px;
    }
}

form label {
    z-index: 99;
}

.navigation .material-icons {
    display: none;
}
.table {
    width: 100%;
}

.MuiTabs-root button.MuiTab-root {
    min-width: 0;
}

@media (min-width: 600px) {
    .table .MuiToolbar-gutters {
        padding-left: 12px;
        padding-right: 12px;
    }
    .table .MuiToolbar-regular {
        min-height: 58px;
    }
}

[draggable] {
    user-select: none;
    /* Required to make elements draggable in old WebKit */
    user-drag: element;
  }

.highcharts-credits {
    display: none;
}
